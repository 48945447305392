@font-face {
  font-family: "ApercuPro";
  src:
    local("ApercuPro"),
    url(./assets/fonts/apercupro-regular.woff2) format("woff2");
}

@font-face {
  font-family: "ApercuPro-black";
  src:
    local("ApercuPro-black"),
    url(./assets/fonts/apercupro-black.woff2) format("woff2");
}

@font-face {
  font-family: "Mavka";
  src:
    local("Mavka"),
    url(./assets/fonts/sweet_mavka_script.woff) format("woff2");
}

body {
  margin: 0;
  font-family: "ApercuPro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "ApercuPro-black";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.image {
  filter: brightness(40%);
}

.mavka {
  font-family: "Mavka";
}

.custom-img {
  background-image: url(./assets/images/prebout.png);
}

.h-screen-50 {
  height: 50vh;
}

.cssbuttons-io {
  position: relative;
  background: linear-gradient(to right, #8e2de2, #4a00e0);
  color: black;
  overflow: hidden;
}

.cssbuttons-io svg {
  width: 1.2em;
  height: 1.2em;
  margin-right: 0.5em;
}

.cssbuttons-io span {
  position: relative;
  z-index: 10;
  transition: color 0.4s;
  display: inline-flex;
  align-items: center;
  padding: 0.8em 1.2em 0.8em 1.05em;
}

.cssbuttons-io::before,
.cssbuttons-io::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.cssbuttons-io::before {
  content: "";
  background: #fff;
  width: 120%;
  left: -10%;
  transform: skew(0deg);
  transition: transform 0.4s cubic-bezier(0.3, 1, 0.8, 1);
}

.cssbuttons-io:hover::before {
  transform: translate3d(100%, 0, 0);
}

.cssbuttons-io:active {
  transform: scale(0.95);
}

.btn {
  width: 170px;
  height: 40px;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.btn9 {
  width: 170px;
  height: 40px;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.btn3 {
  width: 240px !important;
}

.btn span {
  transition: top 0.5s;
}

.btn9 span {
  transition: top 0.5s;
}

.btn-text-one {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.btn-text-two {
  position: absolute;
  width: 100%;
  top: 150%;
  left: 0;
  transform: translateY(-50%);
}

.btn:hover .btn-text-one {
  top: -100%;
}

.btn:hover .btn-text-two {
  top: 50%;
}

.btn9:hover .btn-text-one {
  top: -100%;
}

.btn9:hover .btn-text-two {
  top: 50%;
}
/*
#iprojects {
  background-image: url(./assets/images/interior3.webp);
}
*/

.custom-bg-1 {
  background-image: url(./assets/images/bg.webp);
}

.custom-bg-2 {
  background-image: url(./assets/images/prebout.png);
}

.wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #daf7a6;
  overflow: hidden;
}

.frame {
  border: 10px solid #ff8e00;
  width: $box-size;
  overflow: hidden;
}

.box-container {
  display: flex;
}

.box {
  box-sizing: border-box;
  border: 5px solid #fff;
  background: #00e7ff;
  width: $box-size;
  height: $box-size;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-container {
  display: flex;
}

.button {
  background: #666;
  color: #fff;
  width: 50px;
  text-align: center;
  padding: 10px 20px;
  margin: 10px;
  user-select: none;
  cursor: pointer;
}

.button-2-container {
  display: flex;
}

.button-2 {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background: #e0e0e0;
  margin: 6px;
  cursor: pointer;
}

.active {
  background: #ff9494;
}

.five {
  animation-duration: 2s;
  animation-iteration-count: 3;
}

.stretched-link::after {
  content: "";
  @apply z-10 absolute inset-0 bg-transparent pointer-events-auto;
}
